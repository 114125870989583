<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-card class="rounded ma-4 secondarygrad">
      <v-row>
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <!-- <h2 style="margin-bottom: 30px">
                      Lorem ipsum
                    </h2> -->
              <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                <h1 style="margin-bottom: 40px">Godial + IndiaMart</h1>
              </div>
              <div style="padding: 5px 15px; text-align: center">
                <!-- <h5
                        style="
                          line-height: 17px;
                          font-weight: 500;
                          font-size: 14px;
                        "
                      >
                        How to use?
                      </h5> -->

                <div style="text-align: left">
                  <p
                    style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                  >
                    How to use?
                  </p>
                  <p>
                    1. Visit
                    <a href="https://seller.indiamart.com"
                      >seller.indiamart.com</a
                    >
                    , login to your account
                    <br />
                    2. Settings > Account Settings available on the left
                    navigation.
                    <br />
                    3. Now click on Generate Key. A unique CRM key will be
                    received on your registered email-id.
                    <br />
                    4. Copy the key and your phone into the form beside you and
                    choose your list where you want to get your leads
                    <br />
                    5. Save and we will auto sync your leads every 10 mins
                  </p>
                </div>
                <p class="text-left">
                  For more details visit :
                  <a
                    target="_blank"
                    href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                  >
                    this link</a
                  >
                </p>
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p
                  style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                >
                  Feel free to reach out to us at
                  <a href="mailto:support@godial.cc">support@godial.cc</a>
                  if you are interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card></v-col
        >
        <v-col
          ><v-card flat class="transparent">
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex
                  d-flex
                  justify-center
                  class="mt-10"
                  style="padding-top: 22px"
                  >Enable IndiaMart
                </v-flex>
                <v-flex class="mt-10" d-flex justify-center>
                  <v-switch
                    v-model="indiamart.enabled"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="margin-top:25px; padding: 15px 50px; text-align: center"
          >
            <v-card-title primary-title class="justify-center"
              >IndiaMart Credentials</v-card-title
            >

            <!-- <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                    <v-text-field
                      v-model="indiamart.config.GLUSR_MOBILE"
                      :disabled="!indiamart.enabled"
                      label="Mobile Number"
                      type="number"
                      outlined
                      dense
                      prepend-inner-icon="stay_primary_portrait"
                    ></v-text-field>
                  </v-flex> -->

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-text-field
                v-model="indiamart.config.GLUSR_MOBILE_KEY"
                :disabled="!indiamart.enabled"
                label="Mobile Key"
                type="text"
                outlined
                dense
                prepend-inner-icon="vpn_key"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <!-- <v-select
                      v-bind:disabled="!indiamart.enabled"
                      v-model="indiamart.selectedList"
                      label="Mobile Key"
                      type="text"
                      outlined
                      dense
                      prepend-inner-icon="vpn_key"
                    ></v-select> -->
              <v-autocomplete
                v-model="indiamart.listId"
                :disabled="!indiamart.enabled"
                class="pt-1"
                :items="lists"
                item-text="name"
                item-value="id"
                label="Lists"
                prepend-inner-icon="view_list"
                autocomplete="false"
                outlined
                dense
              />
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn color="primarygrad" dark block @click="setIndiamart"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
  data() {
    return {
      indiamart: {
        type: "indiamart",
        config: {
          GLUSR_MOBILE_KEY: "",
        },
        listId: "",
        companyId: "",
        enabled: false,
      },
      loader: false,
      lists: [],
    };
  },

  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  watch: {},
  created() {
    this.fetchLists();
    this.getIndiaMartDetails();
    this.indiamart.companyId = this.companyDetails.id;
  },
  methods: {
    async getIndiaMartDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=indiamartV2`
      );

      if (resp.body.length > 0) this.indiamart = resp.body[0];
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async setIndiamart() {
      //   if (this.indiamart.config.GLUSR_MOBILE == "") {
      //     return this.$swal({
      //       type: "warning",
      //       text: "Mobile Number Can't be Blank",
      //     });
      //   }

      if (this.indiamart.config.GLUSR_MOBILE_KEY == "") {
        return this.$swal({
          type: "warning",
          text: "Mobile Key Can't be Blank",
        });
      }

      this.loader = true;

      try {
        this.indiamart.companyId = this.companyDetails.id;
        this.indiamart.type = "indiamartV2";
        var res = {};
        //If new user
        if (typeof this.indiamart.id == "undefined") {
          // Add Indiamart Details In Company Data
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.indiamart
          );
        } else {
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.indiamart.id}`,
            this.indiamart
          );
        }

        // Success Message
        if (res.status == 200) {
          // Set Company Object for Store dispatch
          this.getIndiaMartDetails();
          this.loader = false;

          try {
            fb.log("indiamart_added");
          } catch (e) {
            console.log("Error in FB log", e);
          }

          return this.$swal({
            type: "success",
            text: "Indiamart Added to Godial Successfully",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err.body.error.message,
        });
      }
    },
  },
};
</script>

<style></style>
